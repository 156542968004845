import React, { useEffect } from 'react'
import '../App.css'
import team_main from '../images/team_main.jpg'
import Aos from 'aos'
import 'aos/dist/aos.css'
import abd from '../images/abdDP.PNG'
import ali from '../images/jaan.PNG'
import syedagr from '../images/syedaGr_bg.png'
import { FaFacebook, FaLinkedin, FaInstagram } from "react-icons/fa6";

function TeamPage() {

  document.title = 'Team - WorknConnect Innovators'

    useEffect(() => {
        Aos.init({ duration: 2000 })
    }, [])

    const teamMembers = [
        {
            name: 'Syed Ali Abdullah Shah',
            title: 'Co-Founder & CEO',
            image: abd,
            instagram: 'https://instagram.com/syed_abdullah5/',
            facebook: 'https://www.facebook.com/syed.abdullah.52643821',
            linkedin: 'https://www.linkedin.com/in/syed-ali-abdullah-shah-660519336/'
        },
        {
            name: 'Muhammad Ali Jaan',
            title: 'Founder & Director',
            image: ali,
            instagram: 'https://www.instagram.com/ali_jaan_butt/',
            facebook: 'https://www.facebook.com/profile.php?id=100036854552588',
            linkedin: 'https://www.linkedin.com/in/ali-jaan-75ab02253/'
        },
        {
            name: 'Syeda Willaye Haider',
            title: 'Product Manager',
            image: syedagr,
            instagram: 'https://www.instagram.com/worknconnect/',
            facebook: 'https://www.facebook.com/profile.php?id=61563614937620',
            linkedin: 'https://www.linkedin.com/in/worknconnect-innovators-6b009a321/'
        },
    ];




    return (
        <div>

            <div id="team_background" className="h-[40vh] my-10 w-full flex justify-center items-center relative">
                <img src={team_main} alt="" className="  absolute top-0 left-0 w-full h-full object-cover" />

                <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-60 flex flex-col justify-center items-center">
                    <h1
                        data-aos="flip-left"
                        data-aos-easing="ease-out-cubic"
                        data-aos-duration="2000"
                        className="lg:text-7xl text-5xl font-bold text-white z-10 relative after:absolute after:content-[''] after:block after:w-full after:h-1 after:bg-[#367ba0] after:bottom-[-10px] after:left-0"
                    >
                        Our Team
                    </h1>

                </div>
            </div>


            <div className='w-full py-24 bg-gray-100 flex justify-center items-center'>
                <div className='w-[90%]'>
                    <div className='flex lg:justify-between justify-center my-auto'>
                        <h2 className='lg:text-5xl text-3xl lg:text-start text-center font-extrabold text-[#367ba0] mb-14 h-fit'>Meet Majors in our Team</h2>
                    </div>
                    <div className='grid lg:grid-cols-3 grid-cols-1 gap-x-10 lg:gap-y-0 gap-y-20 ' >
                        {
                            teamMembers.map((member, index) => (
                                <div key={index}>
                                    <div className='bg-[#367ba0] w-full lg:h-64 h-48 p-4 relative rounded-lg shadow-lg group '>
                                        <img src={member.image} alt='heroImage' className='relative lg:-top-[4.35rem] -top-[4.5rem] ' />
                                        <div className="hidden w-fit relative lg:-top-64 -top-56 flex-col gap-y-6 group-hover:flex translate-x-4 ">
                                            <a href={member.facebook} target='blank' >
                                                <FaFacebook size={25} color='white' className='cursor-pointer hover:scale-110' />
                                            </a>
                                            <a href={member.linkedin} target='blank' >
                                                <FaLinkedin size={25} color='white' className='cursor-pointer hover:scale-110' />
                                            </a>
                                            <a href={member.instagram} target='blank' >
                                                <FaInstagram size={25} color='white' className='cursor-pointer hover:scale-110' />
                                            </a>
                                        </div>
                                    </div>
                                    <div className='text-center pt-4 font-semibold text-gray-600 text-lg' >
                                        <h1>{member.name}</h1>
                                        <p>{member.title}</p>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>

    )
}

export default TeamPage
